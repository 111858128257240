<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-container>
        <v-row>
          <v-col>
            <h5>Attachments</h5>
          </v-col>
          <v-col cols="auto" class="ml-auto">
            <input
              id="attachment"
              name="attachment"
              ref="attachment"
              type="file"
              @change="selectedFiles"
              hidden
            />
            <v-btn
              outlined
              color="success"
              small
              @click="chooseFiles"
              :loading="btnLoading"
            >
              <v-icon>mdi-file</v-icon>New File
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-data-table
        dense
        :headers="attachmentHeaders"
        :items="attachmentItems"
        class="elevation-0"
        hide-default-footer
      >
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { upload } from "@/api/Files";
export default {
  methods: {
    async selectedFiles() {
      this.btnLoading = true;
      const attachment = this.$refs.attachment;
      const formData = new FormData();
      if (attachment.files.length > 0) {
        const uploadFile = attachment.files[0];
        if (uploadFile.size > 2048 * 1024 * 1024) {
          this.btnLoading = false;
          return alert("2GB 이하의 파일만 업로드가 가능합니다.");
        }
        alert(
          "업로드 용량이 클수록 오래 기다려 주셔야 합니다.\n업로드 로딩이 끝날때 까지 대기해 주시기 바랍니다."
        );
        formData.append("file", uploadFile);
        const data = await upload(formData);
        if (data.success) {
          this.attachmentItems.push(...data.data);
        }
        this.$emit("input", this.attachmentItems);
      } else {
        this.selectedFile = null;
      }
      this.btnLoading = false;
    },
    chooseFiles() {
      const attachment = this.$refs.attachment;
      attachment.click();
    },
    deleteItem(item) {
      this.attachmentItems = this.attachmentItems.filter((e) => {
        return e.file_uuid != item.file_uuid;
      });
      this.$emit("input", this.attachmentItems);
    },
  },

  data() {
    return {
      btnLoading: false,
      attachmentHeaders: [
        { text: "Name", value: "file_name" },
        { text: "Size", value: "file_size" },
        { text: "Mimetype", value: "file_mimetype" },
        { text: "Actions", value: "actions" },
      ],
      attachmentItems: [],
    };
  },
};
</script>
