import { requests } from "@/utils/requests";

export const getProductByContract = async (contract_uuid) => {
  const data = await requests({
    url: `/api/service/product/contract/${contract_uuid}`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export default {
  getProductByContract,
};
